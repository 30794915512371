// import Lazyload from "lazyload"

class PageLoader {
  constructor(pageID, menuData) {
    this.pageID = pageID ?? 0
    this.loadedPageIDs = [] // array of all loaded pages IDs
    this.requestedPageIDs = [] // array of requested but not yet loaded pages
    this.menuData = menuData
    this.topLevelFlatMenu = [] // nach top level menu sortiert = top level mit allen unter menu items
    this.allPageIDs = this.flattenMenuData()
    console.log("page loader created. menu data:", this.menuData)
    this.root_url = localized?.root_url ?? ""

    // intersection observer for triggering page loading
    this.pageTopObserver
    this.pageBottomObserver
    this.stickyTitleObserver
    this.stickyTitleEnterObserver
    this.mobileBreakpoint = 782
    this.mobileView = window.innerWidth < this.mobileBreakpoint
    this.mobileHeaderHeight = 60
    this.marginTop = this.getMarginTop() // for intersection observer margin top
    this.siteHeaderHeight = this.getSiteHeaderHeight()
    this.observerHeight = 200
    this.scrollTime = 400

    this.pageLoading // requested page is loading (not prev / next loadpage)
    this.pageLoadingExtended // when load page is executing and shortly after
    this.pageLoadingExtendedTimeout // for resetting the variable to false

    this.prevPageLoadingCounter = 0 // count the load prev page. reset by load page with scroll . do not load more than one previous page
    this.prevPageLoadingIsLimited = false // is limited when loading a single page by clicking or navigating. until scrolling the page

    this.scrollEventsCounter = 0 // if insert element before
    this.postsContainer = jQuery(".posts-container")
    this.currentPage = jQuery(`div#${pageID}`)
    this.pageSiblings = []
    this.currentPageParentID

    this.scrollY = 0
    this.scrollDir = true // true == scroll down
    this.scrollToPage = [] // array with objects: page id, scrollTo. whether to scroll to the loaded page or not
    this.scrollToCounter = 0
    this.resizeTimeout = null
    this.autoScrolling = false // true = jquery animate scrolltop
    this.autoScrollingTimeout // timeout for resetting the autoscrolling after scrolll ended
    this.lastVisiblePageCheckTimeout // timeout for checking the last visible page
    this.lastVisiblePageCheckPending = false

    this.currentPageID // callback function for the current page id
    this.events({ after: true })

    this.reqPageID = pageID // the page id currently requested to load and scroll to. not loaded by page observer

    if (this.currentPage.length == 0) {
      // page not loaded yet
      this.loadPage(pageID, true) // load page and scroll to it
    } else {
      // page loaded (by php)
      console.log("page " + pageID + " loaded by php")
      this.loadedPageIDs.push(pageID)
      this.prevPageLoadingIsLimited = true
      this.setPathForPageTitle(pageID)
      this.observePage(this.currentPage)
      this.lastVisiblePageCheck()
      const page = this.currentPage
      setTimeout(() => this.scrollTo(page, 50), 1000)
    }
  }

  events(args) {
    const { after } = args
    document.addEventListener("scroll", () => {
      this.scrollDir = window.scrollY >= this.scrollY // true = down to bottom, false = up to the top
      this.scrollY = window.scrollY

      // console.log("Y", this.scrollY)
      if (!this.autoScrolling) {
        if (this.scrollEventsCounter > 1) {
          // user scrolling -> reset prev page loading limit
          this.prevPageLoadingCounter = 0
          this.prevPageLoadingIsLimited = false
        }
        this.scrollEventsCounter++
      }
    })

    window.addEventListener("resize", () => {
      clearTimeout(this.resizeTimeout)
      this.resizeTimeout = setTimeout(() => {
        this.resizeFunction()
      }, 750)
    })
  }

  resizeFunction() {
    // intersection observe: cannot change root margin
    // must be reinstantiated with different values
    this.mobileView = window.innerWidth < this.mobileBreakpoint
    this.marginTop = this.getMarginTop()
    this.siteHeaderHeight = this.getSiteHeaderHeight()
    console.log("resize event in page loader", this.marginTop, this.siteHeaderHeight, this.mobileView)
    if (this.pageTopObserver) {
      this.pageTopObserver.disconnect()
      this.pageTopObserver = null
    }
    if (this.pageBottomObserver) {
      this.pageBottomObserver.disconnect()
      this.pageBottomObserver = null
    }
    if (this.stickyTitleObserver) {
      this.stickyTitleObserver.disconnect()
      this.stickyTitleObserver = null
    }
    if (this.stickyTitleEnterObserver) {
      this.stickyTitleEnterObserver.disconnect()
      this.stickyTitleEnterObserver = null
    }
    jQuery(".post-item-container.post-type-page").each((index, item) => {
      this.observePage(jQuery(item))
    })
  }

  flattenMenuData() {
    const flatMenu = [] // all menu items sequentially ordered
    const topLevelFlatMenu = []
    for (const menuItem of this.menuData) {
      const levelArr = []
      flatMenu.push(menuItem.pageID)
      levelArr.push(menuItem.pageID)
      for (const subMenuItem of menuItem.subMenus) {
        flatMenu.push(subMenuItem.pageID)
        levelArr.push(subMenuItem.pageID)
        for (const subSubMenuItem of subMenuItem.subMenus) {
          flatMenu.push(subSubMenuItem.pageID)
          levelArr.push(subSubMenuItem.pageID)
        }
      }
      topLevelFlatMenu.push(levelArr)
    }
    this.topLevelFlatMenu = topLevelFlatMenu
    // console.log("flat menu", flatMenu, topLevelFlatMenu)
    console.log("top level flat menu", this.topLevelFlatMenu)
    return flatMenu
  }

  //
  async loadPage(pageID, scrollTo, loadNext) {
    //
    this.pageLoadingExtended = true // indicate loadpage is running

    if (scrollTo) {
      // if page requested by clicking or navigating, not by prev / next observer
      this.reqPageID = pageID
      // -> indicate page loading in progress to disable prev / next observer during page load
      this.pageLoading = true

      // this.prevPageLoadingCounter = 0
      // this.scrollEventsCounter = 0
      // this.prevPageLoadingIsLimited = true
    }

    scrollTo = scrollTo ?? false // scrollTo is true when requesting a page by clicking or navigating. not when loading prev or next
    this.setScrollTo(pageID, scrollTo)

    console.log("-------loadPage", pageID, "scroll to it:", this.getScrollTo(pageID))

    const $reqPage = jQuery(`div#${pageID}`)

    // hide all pages except the requested page and all its siblings
    const siblings = this.getPageSiblingsID(pageID) // array of siblings page ids
    const sibString = this.makeSiblingsString(siblings) // siblings string for jquery hide

    // jQuery(".post-item-container").not(`${sibString}`).hide()

    if ($reqPage.length == 0) {
      //  check if page is already requested
      if (!this.requestedPageIDs.includes(pageID)) {
        this.requestedPageIDs.push(pageID)

        console.log(`requested page not yet existing -> load page: ${pageID}`)
        // requested page does not exist -> load it

        try {
          let pageHTML = ""

          await jQuery.getJSON(localized.root_url + "/wp-json/wp/v2/pages/" + pageID, page => {
            console.log(`page: ${pageID} data received`)

            pageHTML = `
          <div id="${page.id}" class="post-item-container post-type-page initialized${page.content.rendered ? `` : ` post-item--empty`}" data-title="${page.title.rendered}" data-history="${page.slug}">
          

            
            <div class="post-item__top" data-id=${page.id}></div>

            ${
              page.content.rendered
                ? `
                <div class="post-item-title" data-title="${page.title.rendered}">${page.title.rendered}</div>
                <div class="post-item-content">    
                  ${page.content.rendered}
                </div>`
                : `
                <div class="post-item-title post-item--empty" data-title="${page.title.rendered}">${page.title.rendered}</div>`
            }
              <div class="post-item__bottom" data-id=${page.id}></div>
            
          </div>
        `
          })

          //  insert the new page at the right place within the posts container
          const neighbour = this.getPageNeighbour(pageID)

          if (neighbour.id) {
            // neighbour found
            const $nei = jQuery(`div#${neighbour.id}`)
            // const sTop = jQuery(window).scrollTop() // store current scroll position
            const sTop = window.scrollY // store current scroll position before insert element

            if (neighbour.position === "after") {
              // neighbour page is after the loaded page -> insert loaded page before neighbour page
              // insert page before -> save scroll position

              // const test = $nei.before(pageHTML) // neighbour is after loaded page -> insert loaded page before neighbour
              this.currentPage = jQuery(pageHTML).insertBefore($nei) // neighbour is after loaded page -> insert loaded page before neighbour
              const h = this.currentPage.height()

              jQuery(window).scrollTop(sTop + h + 1)

              console.log(`||||| insert ${pageID} before`, neighbour.id, "was", sTop, window.scrollY, h)
            } else {
              console.log(`||||| insert ${pageID} after`, neighbour.id, sTop)
              $nei.after(pageHTML) // neighbour is before loaded page -> insert loaded page after neighbour
            }
          } else {
            // no neighbour -> just append page to the posts container
            this.postsContainer.append(pageHTML)
          }

          this.setPathForPageTitle(pageID)

          this.currentPage = jQuery(`div#${pageID}`) // store new page as the current page
          this.loadedPageIDs.push(pageID)
          this.currentPage.hide()
          this.pageID = pageID

          const allPages = jQuery(".post-item-container")

          if (siblings && siblings.length) {
            console.log("fade in siblings", siblings)
            allPages.not(`${sibString}`).hide()
            // fade in self and all siblings if hidden
            jQuery(`${sibString}`).each(function () {
              if (jQuery(this).is(":hidden")) jQuery(this).stop().fadeIn(800)
            })
            // make shure requested page is faded in
            this.currentPage.stop().fadeIn(800)
          } else {
            allPages.not(this.currentPage).hide()
            // fade in self and all siblings if hidden
            if (this.currentPage.is(":hidden")) this.currentPage.stop().fadeIn(800)
          }

          // add the observers for the new page
          this.observePage(this.currentPage)

          if (scrollTo) {
            // scroll to requested page
            this.lastVisiblePageCheck()
          } else {
            // check if last visible page check pending and reschedule it
            if (this.lastVisiblePageCheckPending) this.lastVisiblePageCheck()
            // load prev / next -> remove last page class if not last page
            this.removeLastPageClassIfNotLastPage(siblings)
          }

          // if (this.getScrollTo(pageID)) {
          if (scrollTo) {
            await this.scrollTo(this.currentPage)
          }

          const idx = this.requestedPageIDs.indexOf(pageID)
          if (idx >= 0) delete this.requestedPageIDs[idx]

          this.pageLoading = false // indicate requested page load done

          console.log("+++++ (new) page load finished", pageID)
        } catch (error) {
          console.error("(new )page load error", error)
          // delete id from the requested pages ids
          const idx = this.requestedPageIDs.indexOf(pageID)
          if (idx >= 0) delete this.requestedPageIDs[idx]
        }
      } else {
        console.log("req page already requested", pageID)
      }
      this.pageLoading = false
      clearTimeout(this.pageLoadingExtendedTimeout)
      this.pageLoadingExtendedTimeout = setTimeout(() => (this.pageLoadingExtended = false), 500)
    } else {
      // page exists -> show it
      console.log(`page ${pageID} exists -> show it`)

      this.pageID = pageID
      this.currentPage = $reqPage // set requested page as current page

      if (siblings.length > 1) {
        // // unobserve page bottom (and re-observe it again to force reevaluation of intersection)
        // // this is necessary to load the next page if current content is less than view height
        // this.pageBottomObserver.unobserve(this.currentPage.children(".post-item__bottom")[0])

        // same for titel enter observer---
        const title = this.currentPage.children(".post-item-title")[0]
        // if (title) this.stickyTitleObserver?.unobserve(title)
        // console.log("unobserve title leave", title, this.stickyTitleObserver)

        // hide all pages except self and siblings
        const allPages = jQuery(".post-item-container")

        if (siblings && siblings.length) {
          allPages.not(`${sibString}`).hide()
          // fade in self and all siblings if hidden
          jQuery(`${sibString}`).each(function () {
            if (jQuery(this).is(":hidden")) {
              jQuery(this).stop().fadeIn(800)
              console.log("hidden -> fade in", jQuery(this).data("title"))
            } else {
              // console.log("not hidden", jQuery(this).data("title"))
            }
          })
          // make shure requested page is faded in
          this.currentPage.stop().fadeIn(800)
          console.log("fade in req page", this.currentPage.data("title"))
        } else {
          allPages.not(this.currentPage).hide()
          // fade in self and all siblings if hidden
          this.currentPage.stop().fadeIn(800)
        }

        // allPages.not(`${sibString}`).hide()
        // jQuery(`${sibString}`).each(function () {
        //   if (jQuery(this).is(":hidden")) jQuery(this).fadeIn(800)
        // })

        if (scrollTo) {
          // scroll to requested page
          if (this.lastVisiblePageCheckPending) this.lastVisiblePageCheck()
        } else {
          // remove last page class from all except the last visible page
          this.removeLastPageClassIfNotLastPage(siblings)
        }

        // // observe page bottom again
        // this.observePageBottom(this.currentPage)
        // observe title enter again
        // this.observeStickyTitle(this.currentPage)
      } else {
        console.log("page is empty", sibString, siblings)
      }

      // console.log("show existing page", this.currentPage.position().top)
      if (this.getScrollTo(pageID)) await this.scrollTo(this.currentPage)

      this.pageLoading = false
      this.pageLoadingExtended = false
    }
  }

  setScrollTo(pageID, scrollTo) {
    // wether to scroll or not is stored per page
    // store the scrollTo value to the object array
    const stObj = this.scrollToPage.find(obj => {
      return obj.id === pageID
    })
    if (stObj) {
      // object with page id already in array
      stObj.scrollTo = scrollTo
    } else {
      // push new obj into array
      this.scrollToPage.push({
        id: pageID,
        scrollTo: scrollTo
      })
    }
  }

  getScrollTo(pageID) {
    // get wether to scroll to the page
    const stObj = this.scrollToPage.find(obj => {
      return obj.id === pageID
    })
    let scrollTo
    if (stObj) scrollTo = stObj.scrollTo
    else scrollTo = false
    return scrollTo
  }

  lastVisiblePageCheck() {
    console.log("last visible page check scheduled")
    // find the last visible page (".post-item-container") and set the min-height to 100vh to ensure scrolling is possible
    this.lastVisiblePageCheckPending = true
    clearTimeout(this.lastVisiblePageCheckTimeout)

    this.lastVisiblePageCheckTimeout = setTimeout(() => {
      console.log("last visible page check executes")

      const allPages = jQuery(".post-item-container")

      // get the last visible page (-> min  height 100vh)
      const lastVisiblePage = allPages.filter(":visible").last()

      if (lastVisiblePage.length) {
        allPages.not(lastVisiblePage).removeClass("post-item--last-page")

        // const offsetTop = lastVisiblePage.offset().top
        // const scrollable = offsetTop + lastVisiblePage.height() - window.innerHeight - this.observerHeight > 0
        // console.log("last vis check. page offset top: ", lastVisiblePage.offset().top, scrollable)
        // // add last page class, if not scrollable
        // if (!scrollable) lastVisiblePage.addClass("post-item--last-page")
        lastVisiblePage.addClass("post-item--last-page")

        // // if page top + page height < window height + observer height -> add page height 100vh
        // if (lastVisiblePage.position().top + lastVisiblePage.height() < window.innerHeight + this.observerHeight) {
        // } else {
        //   // lastVisiblePage.removeClass("post-item--last-page")
        // }
        console.log("***** check last visible page:", lastVisiblePage.attr("id"), lastVisiblePage.data("title"), lastVisiblePage.position().top, lastVisiblePage.height(), window.innerHeight)
      } else {
        allPages.removeClass("post-item--last-page")
      }
      this.lastVisiblePageCheckPending = false
    }, 500)
  }

  removeLastPageClassIfNotLastPage(siblings) {
    // siblings = array of sibling ids
    const allPages = jQuery(".post-item-container")

    // get the last visible page (-> min  height 100vh)
    const lastVisiblePage = allPages.filter(":visible").last()
    if (lastVisiblePage.length) {
      allPages.not(lastVisiblePage).removeClass("post-item--last-page")
      console.log("last visible page class removed. except from last:", lastVisiblePage.attr("id"), lastVisiblePage.data("title"))

      // if last visible page is the last page of the siblings = last subpage of a top page
      // and page height is less than window height

      if (lastVisiblePage.height() < window.innerHeight && siblings.length && lastVisiblePage.attr("id") == siblings[siblings.length - 1]) {
        lastVisiblePage.addClass("post-item--last-page")
      }
    }
  }

  getPageNeighbour(pageID) {
    // get the nearest loaded neighbour page of the pageID
    const neighbour = {
      id: null,
      position: ""
    }

    const pageIndex = this.allPageIDs.indexOf(pageID)
    let fwdInd = pageIndex + 1
    let bwdInd = pageIndex - 1
    let found = false

    while (!found && (fwdInd < this.allPageIDs.length || bwdInd >= 0)) {
      if (fwdInd < this.allPageIDs.length && this.loadedPageIDs.includes(this.allPageIDs[fwdInd])) {
        found = true
        neighbour.id = this.allPageIDs[fwdInd]
        neighbour.position = "after"
      } else if (bwdInd >= 0 && this.loadedPageIDs.includes(this.allPageIDs[bwdInd])) {
        found = true
        neighbour.id = this.allPageIDs[bwdInd]
        neighbour.position = "before"
      }
      fwdInd++
      bwdInd--
    }

    // console.log(`get nearest existing neighbour of page ${pageID}`, neighbour)

    return neighbour
  }

  getPageSiblingsID(pageID) {
    // get the id of the sibling pages in the same top level menu

    let index
    let siblings
    // find in which  of top level menu the page is in
    this.topLevelFlatMenu.every((elem, ind) => {
      const i = elem.indexOf(pageID)
      if (i >= 0) {
        index = ind
        return false
      }
      return true
    })

    if (index >= 0) {
      siblings = this.topLevelFlatMenu[index]
    } else {
      // id not found in menu -> page id is only sibling
      siblings = [pageID]
    }

    console.log(pageID, "page siblings:", siblings)
    return siblings
  }

  makeSiblingsString(siblings) {
    let sibString = ""
    if (Array.isArray(siblings) && siblings.length) {
      for (const sibling of siblings) {
        sibString = sibString + "#" + sibling + ", "
      }
    }
    sibString = sibString.slice(0, -2)
    // console.log("sib string", sibString)
    return sibString
  }

  //
  setPathForPageTitle(id) {
    const indexes = this.getMenuIndexesOfCurrentPage(id)
    if (indexes.index != null && indexes.index >= 0) {
      const postItemTitle = jQuery(`#${id}`).children(".post-item-title")

      const title = postItemTitle.data("title")

      // adjust title of current page with top and sub page title
      const title1st = `${this.menuData[indexes.index].slug}`
      const title2nd = `${indexes.subIndex != null && indexes.subIndex >= 0 ? `${this.menuData[indexes.index].subMenus[indexes.subIndex].slug}` : ""}`
      const title3rd = `${indexes.subSubIndex != null && indexes.subSubIndex >= 0 ? `${this.menuData[indexes.index].subMenus[indexes.subIndex].subMenus[indexes.subSubIndex].slug}` : ""}`

      const pageID1st = `${this.menuData[indexes.index].pageID}`
      const pageID2nd = `${indexes.subIndex != null && indexes.subIndex >= 0 ? `${this.menuData[indexes.index].subMenus[indexes.subIndex].pageID}` : ""}`

      postItemTitle.html(`<h1 class="post-item-title__title">${title}</h1><div class="post-item-title__path">${title1st != "" && title2nd != "" ? `<span data-id="${pageID1st}">\u25BC&nbsp;${title1st}</span>` : ``}${title2nd != "" && title3rd != "" ? `<span data-id="${pageID2nd}">\u25BC&nbsp;${title2nd}</span>` : ``}</div>`)
      console.log("path for title. indexes", title)
    }
  }

  observePage(page) {
    const pageID = page.attr("id")
    console.log("observePage. page:", pageID, page.data("title"))

    this.observeStickyTitle(page)
    this.observeStickyTitleEnter(page)

    // check if previous page is loaded. observe page top if not.
    const prevID = this.getPrevPageID(pageID)
    if (prevID && jQuery(`#${prevID}`).length == 0) {
      // if there is a previous page and not loaded yet -> observe page top
      this.observePageTop(page)
    }
    // check if next page is loaded. observe page botom if not.
    const nextID = this.getNextPageID(pageID)
    if (nextID && jQuery(`#${nextID}`).length == 0) {
      // if there is a next page and not loaded yet -> observe page top
      this.observePageBottom(page)
    }
  }

  // observe page top element for each page -> loads the previous page
  observePageTop(page) {
    console.log("observePageTop:", page.attr("id"), page.data("title"))
    if (!this.pageTopObserver) {
      this.pageTopObserver = new window.IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              console.log(`top observer intersecting ${entry.target.dataset.id}`, entry)

              const pageID = entry.target.dataset.id // current page id

              // if (this.reqPageID === pageID || (!this.pageLoading && !this.pageLoadingExtended && !this.scrollDir)) {
              if (this.reqPageID === pageID || !this.pageLoading) {
                // if requested page's top observer detects intersection or no page load in progress
                // if the requested page top observer is intersecting or no requested page load in progress
                // only when page top enters the viewport from above
                // if (!this.scrollDir)
                // if (entry.intersectionRect.y >= entry.rootBounds.y && entry.intersectionRect.y < entry.rootBounds.y + 10 && !this.scrollDir) {
                // if (entry.intersectionRect.y >= entry.rootBounds.y && entry.intersectionRect.y < entry.rootBounds.y + 10) {
                if (entry.intersectionRect.y >= entry.rootBounds.y) {
                  // top of a page is intersecting and we scroll up -> show / load previous page

                  const prevID = this.getPrevPageID(pageID)

                  if (prevID) {
                    // if (this.prevPageLoadingCounter < 1 || !this.prevPageLoadingIsLimited) {
                    console.log(`top observer: page ${entry.target.parentNode.dataset.title} (${pageID}) load prev page: ${prevID}`, this.prevPageLoadingCounter, this.prevPageLoadingIsLimited)
                    this.loadPage(prevID, false, false) // load previous page, no scroll to , not next page
                      .then(() => {
                        console.log("prev page loaded", prevID)
                        this.prevPageLoadingCounter++
                        // stop observing page top after previous page was loaded
                        this.pageTopObserver.unobserve(entry.target)
                      })
                      .catch(err => {
                        console.error("load prev failed", prevID, err)
                      })
                    // }
                  } else {
                    console.log("observer top: no prev id")
                  }
                } else {
                  console.log("observer top: not within bounds", entry)
                }
              } else {
                console.log("observer top: page is loading", this.scrollDir)
              }
            }
          })
        },
        {
          root: null,
          rootMargin: `-${this.marginTop}px 0px 0px 0px`,
          threshold: 0.1 // set offset 0 means trigger if atleast 0% of element in viewport
        }
      )
    }
    const pageBottom = page.children(".post-item__top")[0]
    pageBottom ? this.pageTopObserver.observe(pageBottom) : ""
  }

  getPrevPageID(currentID) {
    // console.log("get prev page id", currentID)
    let prevID = null

    let index, subIndex

    // find in which  of top level menu the page is in
    this.topLevelFlatMenu.every((elem, ind) => {
      const i = elem.indexOf(currentID)
      if (i >= 0) {
        index = ind
        subIndex = i
        return false
      }
      return true
    })

    if (index >= 0 && subIndex > 0) {
      prevID = this.topLevelFlatMenu[index][subIndex - 1]
    }

    return prevID
  }

  // observe page bottom element for each page -> loads the next page
  observePageBottom(page) {
    console.log("observePageBottom:", page.attr("id"), page.data("title"))

    if (!this.pageBottomObserver) {
      this.pageBottomObserver = new window.IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              console.log("bottom observer intersecting", entry.target.dataset.id)
              const pageID = entry.target.dataset.id
              // console.log(`bottom of page ${entry.target.dataset.id} is intersecting. dont observe bottom:`, this.dontObserveBottom)
              if (this.reqPageID === pageID || !this.pageLoading) {
                // only when entering from below
                if (entry.intersectionRect.y >= entry.rootBounds.y) {
                  const nextID = this.getNextPageID(pageID)

                  if (nextID) {
                    console.log(`bottom observer: page ${entry.target.parentNode.dataset.title} (${pageID}) load next page: ${nextID}`)
                    this.loadPage(nextID, false, true) // load page, don't scroll, next page
                      .then(() => {
                        console.log("next page loaded", nextID)
                        // stop observing page bottom after the next page was loaded
                        this.pageBottomObserver.unobserve(entry.target)
                      })
                      .catch(err => {
                        console.error("load next failed", nextID, err)
                      })
                  }
                }
              }
            }
          })
        },
        {
          root: null,
          rootMargin: "0px 0px 0px 0px",
          threshold: 0 // set offset 0 means trigger if atleast 0% of element in viewport
        }
      )
    }
    const pageBottom = page.children(".post-item__bottom")[0]
    pageBottom ? this.pageBottomObserver.observe(pageBottom) : ""
  }

  getNextPageID(currentID) {
    let nextID = null
    let index, subIndex

    // find in which  of top level menu the page is in
    this.topLevelFlatMenu.every((elem, ind) => {
      const i = elem.indexOf(currentID)
      if (i >= 0) {
        index = ind
        subIndex = i
        return false
      }
      return true
    })

    if (index >= 0 && this.topLevelFlatMenu[index].length > subIndex + 1) {
      nextID = this.topLevelFlatMenu[index][subIndex + 1]
    }

    // console.log("get next page id", nextID, "from page:", currentID)
    return nextID
  }

  getMenuIndexesOfCurrentPage(currentID) {
    let indexes = {
      index: null,
      subIndex: null,
      subSubIndex: null
    }

    for (let i = 0; i < this.menuData.length; i++) {
      // console.log(this.menuData[i])
      if (currentID === this.menuData[i].pageID) {
        // id found in menu
        indexes.index = i
        break
      }

      for (let j = 0; j < this.menuData[i].subMenus.length; j++) {
        // console.log(this.menuData[i].subMenus[j])
        if (currentID === this.menuData[i].subMenus[j].pageID) {
          // id found in sub menu
          indexes.index = i
          indexes.subIndex = j
          break
        }

        for (let k = 0; k < this.menuData[i].subMenus[j].subMenus.length; k++) {
          // console.log(this.menuData[i].subMenus[j].subMenus[k])
          if (currentID === this.menuData[i].subMenus[j].subMenus[k].pageID) {
            // id found in sub sub menu
            indexes.index = i
            indexes.subIndex = j
            indexes.subSubIndex = k
            break
          }
        }
      }
    }
    // console.log("menu indexes found", indexes)
    return indexes
  }

  getPageIDFromMenuItemClasslist(elem) {
    // get page id from menu item classlist (wpse-object-id-XX)
    let classlist = elem.attr("class").split(/\s+/)
    let pageID
    jQuery.each(classlist, function (index, item) {
      if (item.startsWith("wpse-object-id-")) {
        const splits = item.split("wpse-object-id-")
        if (splits.length > 1) pageID = splits[1]
        return false
      }
    })
    return pageID
  }

  observeStickyTitle(page) {
    // observe when the page title leaves the viewport (is not completely in the viewport anymore). threshold = 1
    // the viewport top margin is minus the height of the adminbar plus site header
    // bottom margin is 0
    //  margin top is 2 px lower than the content container
    // so when the title is at the top it's not in the viewport anymore and the title is sticky
    // on the bottom we ignore when the title leaves the viewport

    if (!this.stickyTitleObserver) {
      // let adminH = getComputedStyle(document.documentElement).getPropertyValue("--adminBarHeight")

      this.stickyTitleObserver = new IntersectionObserver(
        ([e]) => {
          const sticky = e.intersectionRatio < 1 // only sticky when not completely in the viewport (== 1)
          const visible = e.intersectionRect.y >= e.rootBounds.y && e.boundingClientRect.y < e.rootBounds.height // in viewport?
          const id = e.target.parentElement.id

          e.target.classList.toggle("is-sticky", e.intersectionRatio < 1 && e.boundingClientRect.y < e.rootBounds.height)
          if (sticky && visible && id) {
            // title is about to leave the page -> sticky, current page
            // this is the current Title
            console.log("current title leaving the viewport -> is sticky", id, e)
            this.currentPageID(id) // callback
          }
        },
        { rootMargin: `-${this.marginTop + 3}px 0px 0px 0px`, threshold: [1] }
      )
      // console.log(this.stickyTitleObserver)
    }

    const title = page.children(".post-item-title")[0]
    // console.log("title", title)
    if (title) this.stickyTitleObserver.observe(title)
  }

  observeStickyTitleEnter(page) {
    // observe when the page title enters the viewport. threshold = 0.1

    if (!this.stickyTitleEnterObserver) {
      // $(":root").css("--siteHeaderHeight")
      // let adminH = getComputedStyle(document.documentElement).getPropertyValue("--adminBarHeight")

      this.stickyTitleEnterObserver = new IntersectionObserver(
        ([e]) => {
          if (e.isIntersecting) {
            console.log("title enter intersecting", e.target.parentElement.id, e, this.scrollDir)
            // exclude entering the viewport from below

            // if (e.intersectionRect.y >= e.rootBounds.y && e.intersectionRect.y < e.rootBounds.y + 200) {
            if (e.intersectionRect.y >= e.rootBounds.y && !this.scrollDir) {
              // title enters viewport from above
              const id = e.target.parentElement.id
              if (id) {
                console.log("current title from title enter")
                this.currentPageID(id)
              }
            }
          }
        },
        { rootMargin: `-${this.marginTop}px 0px 0px 0px`, threshold: [0.1] }
      )
    }

    const title = page.children(".post-item-title")[0]
    if (title) this.stickyTitleEnterObserver.observe(title)
  }

  getMarginTop() {
    // margin top = adminbar height + header height
    let ah = 0
    const adminbar = document.querySelector("#wpadminbar")
    if (adminbar) ah = adminbar.offsetHeight // height including: padding and border, without margin
    let hh = 0
    if (this.mobileView) hh = this.mobileHeaderHeight
    else hh = document.querySelector(".site-header").offsetHeight
    console.log("margin top for observer", ah + hh)
    return ah + hh
  }

  getSiteHeaderHeight() {
    // header height = site header height
    let hh = 0
    if (this.mobileView) hh = this.mobileHeaderHeight
    else hh = document.querySelector(".site-header").offsetHeight
    return hh
  }

  scrollTo(page, time) {
    time = time ?? this.scrollTime
    return new Promise((resolve, reject) => {
      this.scrollToCounter = 0 // reset the scroll counter
      this.scrollFunc(page, time, resolve)
    })
  }

  scrollFunc(page, time, resolve) {
    const top = Math.max(0, Math.round(page.position().top))

    // if (window.scrollY != top) {
    console.log("->->->->---- scroll to page:", page.attr("id"))

    this.autoScrolling = true // indicate scrolling in progress
    this.scrollToCounter++

    jQuery("html, body")
      .stop(true, true)
      .animate(
        {
          scrollTop: top
        },
        time,
        () => {
          // complete
          const top = Math.max(0, Math.round(page.position().top))
          if (top !== window.scrollY && this.scrollToCounter < 10) {
            this.scrollFunc(page, time, resolve)
          } else {
            clearTimeout(this.autoScrollingTimeout)
            this.autoScrollingTimeout = setTimeout(() => {
              this.autoScrolling = false
              console.log("scrollTo finished", page.attr("id"), window.scrollY, top)
            }, 200)
            resolve()
          }
        }
      )
    // } else {
    //   // scroll position is already correct
    //   jQuery("html, body").scrollTop(top)
    //   console.log("->->->->---- is already ok scrolled:", page.attr("id"), window.scrollY)
    //   resolve()
    // }
  }

  jumpTo(top) {
    return new Promise((resolve, reject) => {
      window.scrollTo(0, top)

      setTimeout(() => {
        window.scrollTo(0, top)
        resolve()
      }, 100)
      setTimeout(() => {
        window.scrollTo(0, top)
        resolve()
      }, 200)
    })
  }

  addLazyloadToElement(elem) {
    // https://github.com/tuupola/lazyload
    // $("img.lazyload").lazyload();
    // lazyload();

    // const elem = document.querySelector(`[id="${elemID}"]`);
    // const elem = $elem[0]
    const images = elem.querySelectorAll("img.lazyload")

    // console.log("lazyload images", images, " in container ", $elem);

    new Lazyload(images, {
      root: null,
      rootMargin: "50px",
      threshold: 0
    })

    // add load event listener for lazyload images
    // $elem.find(`img.lazyload`).on("load", function () {
    //   // console.log("img on elem xx loaded", $elem.attr('id'));
    //   jQuery(this).addClass("loaded") // add loaded class to image -> fade in with css opacity 1
    // })

    images.forEach(image => {
      image.addEventListener("load", () => {
        image.classList.add("loaded")
      })
    })
  }
}

export default PageLoader
