// import style index file
import "./styles/index.scss"

// Our modules / classes
import LoadMorePosts from "./scripts/modules/LoadMorePosts"
import PageLoader from "./scripts/modules/PageLoader"
import Search from "./scripts/modules/Search"

let loadMorePosts
let pageLoader

var debug = true
const mobileBreakpoint = 782
let mobileView = false

const ScrollDelta = 10 // how many pixels to scroll to call scroll dependent function

let adminBarHeight = 0
let siteTitleHeight = 0
let siteHeaderHeight = 0
let siteHeaderTimeout // used for collapsing and expanding the header
let siteHeaderCollapsed = false

let titleHeight = 0
let navHeight = 0
let navOpen = false

let historyUpdate = true // to push to history or not
let orientationLandscape = true

let lastScroll = 0
let prevScrollDelta = 0
let scrollYPositions = {}
let scrollToElementTimeout

let currentContentID = -1
let prevContentID = -1

const menuAnimationTime = 400
let autoScrolling = false
let autoScrollingTimeout

let requestAllPartsInterval = null
let nowWithKeyboard = false // if true mobile keyboard is showing
let observer
let navAndTitleHeightTimeout
let touchsupport
let deltaY = 0
let prevY = 0
let requestedPageID = -1 // when clicking on a menu item for a page. the page id we want to load
let requestedPageIDTimeout
let scrollPositionToRestore = 0 // when open / close extended menu scroll position needs to be restored

jQuery(function ($) {
  // if (debug) console.log("document ready!")
  const $html = $("html")
  $html.addClass("js") // js is working
  // console.log("root url:", localized.root_url)
  let firstLoad = true // indicate page loaded

  if (checkFlexGap()) {
    document.documentElement.classList.add("flexbox-gap")
  } else {
    document.documentElement.classList.add("no-flexbox-gap")
  }

  // console.log("nonce", localized.nonce, localized.nonce_field);
  if ("scrollRestoration" in history) {
    history.scrollRestoration = "manual"
  }
  // for ios touch -> enable active state for links
  document.addEventListener(
    "touchstart",
    function () {
      // console.log("touchstart")
    },
    false
  )

  let pathname = window.location.pathname

  // console.log('pathname', pathname);
  // console.log('history state', history.state);

  const blogName = document.querySelector('meta[name="name"]').content
  const blogDescription = document.querySelector('meta[name="description"]').content

  // console.log(blogName, blogDescription);

  // -----------------------------------------------

  const siteContainer = $(".site-container")

  const siteHeader = $(".site-header")
  const siteHeaderDescription = $(".site-header__description") // schweizerische gesellschaft....
  const siteHeaderLanguageButton = $(".language-icon")
  let languageSwitcherHoverTimeout
  const languageSwitcher = $(".extended-menu__language-switcher")
  const searchIcon = $(".search-icon")
  const openCloseMenu = $(".nav-icon")
  const downloadPageButton = $(".download-icon")
  let extMenuOpen = false

  const siteTitle = $(".site-header__title")
  const siteTitleLink = $(".site-header__link")

  const siteMenu = $(".site-menu") // container for the main menu and download menu

  const menuItemHome = $(".menu-item.menu-item-home")
  const allMenuItems = $(".menu-item") // all menu items
  const allMenuLinks = $(".menu-item > a") // all menu item links
  // const allSubMenus = $("ul.sub-menu") // all sub menus
  const allSubMenus = $(".menu-item-parent-id-0 > .sub-menu") // all first level sub menus
  const menuMainSubMenus = $(".menu-main .menu-item-parent-id-0 > .sub-menu") // all first level sub menus of the main nav menu. For slideUp/ Down

  // menu-download
  const downloadMenu = $(".menu-download")
  const downloadMenuItems = $(".menu-download .menu-item")

  // menu-faq
  const faqMenu = $(".menu-faq")
  const faqMenuItems = $(".menu-faq .menu-item")

  // menu-additional
  const additionalMenuItems = $(".menu-additional .menu-item")

  // menu-main = menu nav
  const menuMain = $("ul.menu-main")
  const menuItems = $(".menu-main .menu-item") // all menu items in main menu
  const menuNavLinks = $(".menu-main > li.menu-item > a") // top level nav links
  const menuNavSubLinks = $(".menu-main > .menu-item > .sub-menu > li.menu-item > a") // 2. level nav links
  const menuNavSubSubLinks = $(".menu-main > .menu-item > .sub-menu > .menu-item > .sub-menu > li.menu-item > a") // 3. level nav links

  const menuNavItemsLevel1 = $(".menu-main > li.menu-item") // top level nav menu items
  const menuNavItemsLevel2 = $(".menu-main > .menu-item > .sub-menu > li.menu-item") // 2. level nav links
  const menuNavItemsLevel3 = $(".menu-main > .menu-item > .sub-menu > .menu-item > .sub-menu > li.menu-item") // 3. level nav links

  const menuDownloadLinks = $("ul.menu-download > li.menu-item > a")
  const menuDownloadItemsLevel1 = $("ul.menu-download > li.menu-item") // top level download menu links (only 1 == downloads)
  const menuDownloadItemsLevel2 = $("ul.menu-download > .menu-item > .sub-menu > li.menu-item") // 2. level Download links
  const menuDownloadItemsLevel3 = $("ul.menu-download > .menu-item > .sub-menu > .menu-item > .sub-menu > li.menu-item") // 3. level Download links

  const menuFaqItemsLevel1 = $(".menu-faq > li.menu-item") // top level faq menu items

  const extendedMenu = $(".extended-menu")
  const extendedMenuItems = $(".extended-menu .menu-item")

  const siteMenuAndContent = $(".site-menu-content-container-wrapper")
  const contentContainer = $(".content-container")

  const kontaktMenuEmail = $(".kontakt-menu .email-plain-mail")

  // -----------------------------------------------------------------------------------------------------

  const $body = $("body")
  const languageSlug = $body.data("language-slug")
  console.log("lang slug", languageSlug)
  const $mainMenu = $(".menu-main")
  const homeID = $body.data("home-id").toString() // post id of the home page as string

  setLandscape()
  touchSupport()

  // set background color from customizer
  document.documentElement.style.setProperty("--bgcolor", $("body").data("bgcolor"))

  calcVhPropertyFromClientHeight() // for mobile viewport height on ios
  setAdminBarHeight()
  navAndTitleHeight()
  checkMobileView()

  const search = new Search()

  if (extMenuOpen) closeExtendedMenu() // -> append language switcher to the site header language icon on page load

  // build menu data
  const menuData = buildMenuData()

  restructureMainMenu() // separate the menu in two divs for the extended menu

  // hide and fade in nav and content elements with visibility hidden
  siteHeader.hide()
  siteHeader.css("visibility", "visible")
  siteHeader.show()
  // siteHeader.fadeIn(400)

  siteContainer.hide()
  siteContainer.css("visibility", "visible")
  siteContainer.fadeIn(400)

  setTimeout(() => {
    // safety
    navAndTitleHeight()
  }, 100)

  const postsContainer = $(".posts-container")
  let postItemContainers = $(".post-item-container")

  // postItemContainers.hide() // hide all post items

  // site header nav icons ----------------------------------------------------------------------------------------------------
  // siteHeaderLanguageButton.add(languageSwitcher).on("mouseenter", function (e) {
  //   languageSwitcher.addClass("show")
  // })
  // siteHeaderLanguageButton.add(languageSwitcher).on("mouseleave", function (e) {
  //   clearTimeout(languageSwitcherHoverTimeout)
  //   languageSwitcherHoverTimeout = setTimeout(() => {
  //     languageSwitcher.removeClass("show")
  //   }, 500)
  // })

  // languageSwitcher.children("a").on("click", function (e) {
  //   // language link de, fr, en ,it
  //   // if ($(this).hasClass("language-disabled")) {
  //   //   e.preventDefault()
  //   // }
  // })

  // menu and title navigation ------------------------------------------------------------------------------------------------
  extendedMenuItems.on("click", function (e) {
    closeExtendedMenu()
  })

  allMenuLinks.on("click", function (e) {
    const menuNavItem = $(this).parent()
    const isPage = menuNavItem.hasClass("menu-item-object-page")
    const isHome = menuNavItem.hasClass("menu-item-home")
    const id = getObjectIDFromMenuItem(menuNavItem)

    console.log("Menu Item link clicked", id)

    if (extMenuOpen) closeExtendedMenu()

    const slug = getSlugFromURL($(this).attr("href"))

    // special case: in the menu-additional there are 2 links we want to handle separately.
    // faq and downloads
    if ((slug === "faq/" || slug === "downloads/") && $(this).parent().parent().hasClass("menu-additional")) {
      console.log("is faq or download page")
    }

    if (isPage || isHome) {
      // requestedPageID = id
      // clearTimeout(requestedPageIDTimeout)
      // requestedPageIDTimeout = setTimeout(() => {
      //   requestedPageID = -1
      // }, 500)
      // links in the menu are either pages or home = blog page
      e.preventDefault()

      updateHistory($(this).attr("href"), $(this).text())
      // if different url -> open the page
      if (isPage) {
        openPageLink($(this)) // and set the current menu item
      } else {
        openBlogPage()
      }
    }
  })

  // open the download page
  downloadPageButton.on("click", function (e) {
    e.preventDefault()
    const ID = $(this).data("id").toString()
    // find the download menu item in the download menu and set it as current menu item
    const menuItem = allMenuItems.filter(`.wpse-object-id-${ID}`)
    if (menuItem.length) setCurrentMenuItem(menuItem) // 2 menu items . 1 in dl menu 1 in additional menu
    // history update
    updateHistory($(this).attr("href"), $(this).attr("title"))
    openPageWithID(ID)
  })

  searchIcon.on("click", function () {
    console.log("search button click")
  })

  openCloseMenu.on("click", function () {
    if (openCloseMenu.hasClass("checked")) {
      closeExtendedMenu(true)
    } else {
      openExtendedMenu()
    }
  })

  // menu hadling --------------------------------------------

  // open the extended menu
  function openExtendedMenu() {
    console.log("open extended menu", window.scrollY)
    scrollPositionToRestore = window.scrollY
    extMenuOpen = true
    collapseHeader()
    $html.addClass("extended-menu-open")
    openCloseMenu.addClass("checked")
    siteMenuAndContent.hide()
    // move duplicate elements
    menuMain.appendTo($(".extended-menu__main-menu-container")) // add the main menu to the extended menu overlay
    addLanguageSwitcherToExtMenu()
    extendedMenu.fadeIn()
  }

  function addLanguageSwitcherToExtMenu() {
    if (mobileView) languageSwitcher.insertBefore(".extended-menu__main-menu-container")
    else languageSwitcher.insertBefore(".extended-menu__additional-menu")
  }

  function closeExtendedMenu(restoreScrollPosition) {
    extMenuOpen = false
    $html.removeClass("extended-menu-open")
    openCloseMenu.removeClass("checked")
    extendedMenu.hide()
    //
    menuMain.insertAfter(downloadMenu)
    languageSwitcher.appendTo(".language-icon")
    siteMenuAndContent.fadeIn()

    if (restoreScrollPosition) {
      console.log("restore scroll position", scrollPositionToRestore)
      window.scrollTo(0, scrollPositionToRestore)
    }
    console.log("close extended menu", window.scrollY)
  }

  function openNav() {
    if (!navOpen) {
      navOpen = true
      $html.addClass("site-menu--visible")
      siteMenu.addClass("show")
      // siteTitle.addClass("big")
      // console.log("\\\\\\ open nav")
    }
  }
  function closeNav() {
    if (navOpen) {
      navOpen = false
      $html.removeClass("site-menu--visible")
      siteMenu.removeClass("show")
      console.log("///// close nav")
      // siteTitle.removeClass("big")
    }
  }

  function collapseHeader() {
    if (!siteHeaderCollapsed && !pageLoader?.autoScrolling) {
      console.log("\\\\collapse header")
      siteHeaderCollapsed = true
      siteHeader.addClass("collapsed")
      clearTimeout(siteHeaderTimeout)
      siteHeaderTimeout = setTimeout(() => {
        navAndTitleHeight()
      }, 400)
    }
  }

  function expandHeader() {
    if (siteHeaderCollapsed) {
      siteHeaderCollapsed = false
      siteHeader.removeClass("collapsed")
      clearTimeout(siteHeaderTimeout)
      siteHeaderTimeout = setTimeout(() => {
        navAndTitleHeight()
      }, 400)
    }
  }
  // --------------------------------------------------------------------------------------------------------------------------------------------------------
  function openPageLink($link) {
    console.log("open page link", $link.attr("href"))

    const menuItem = $link.parent() // menu item of link

    const pageID = getObjectIDFromMenuItem(menuItem)

    setCurrentMenuItem(menuItem)

    openPageWithID(pageID)
  }

  function setCurrentMenuItem(menuItem) {
    console.log("!!!!! set current menu item", menuItem.children("a").text())
    // works with main, download and faq menu
    // this function takes a menu item and decides which level it is on (main level, 1st sublevel, 2nd sublevel )
    // and sets it as the current active menu item
    // and open closes the menu structure accordingly

    const isHome = menuItem.hasClass("menu-item-home")
    const hasChildren = menuItem.hasClass("menu-item-has-children")
    const parentSubmenu = menuItem.parent(".sub-menu")
    const isSubmenuItem = parentSubmenu.length // menu item is in a submenu
    let isSubSubmenuItem = false

    let isDownloadMenuItem = downloadMenuItems.filter(menuItem).length > 0
    let isFaqMenuItem = faqMenuItems.filter(menuItem).length > 0
    const isAdditionalMenuItem = additionalMenuItems.filter(menuItem).length // menu item is in the additional menu

    let menuMainSubMenuToShow // the top submenu of the main nav the current menu item is in, if it's in the main nav and should be shown
    let menuMainTopMenuItemToShow // the top menu item the current menu item is part of, if it's in the main nav menu

    if (isAdditionalMenuItem) {
      // -> check if it is download or faq item in the additional menu
      const slug = getSlugFromURL(menuItem.children("a").attr("href"))
      if (slug === "faq/") isFaqMenuItem = true
      if (slug === "downloads/") isDownloadMenuItem = true
    }

    let menuLevel = -1 // set to invalid value

    // determine menu level: 0 = top level, 1 = sub menu, 2 = sub sub menu
    if (isSubmenuItem) {
      // is submenu -> check if it is a sub sub menu item
      isSubSubmenuItem = parentSubmenu.parent().parent(".sub-menu").length > 0
    }

    if (!isSubmenuItem) menuLevel = 0
    else if (isSubmenuItem && !isSubSubmenuItem) menuLevel = 1
    else if (isSubSubmenuItem) menuLevel = 2

    // get the top level submenu according the level of the menu item
    switch (menuLevel) {
      //
      case 0: {
        // top level menu item
        // console.log("Set menu item: Top Level Menu Item")

        const submenu = menuItem.children(".sub-menu")
        menuMainSubMenuToShow = submenu
        menuMainTopMenuItemToShow = menuItem // menu item is top level menu item
        break
      }

      case 1: {
        // console.log("Set menu item: 1st Submenu Item -----------------")

        menuMainSubMenuToShow = parentSubmenu
        menuMainTopMenuItemToShow = parentSubmenu.parent() // top level menu item
        break
      }

      case 2: {
        // console.log("Set menu item: 2nd Submenu level")

        // check if parent submenu is also a sub menu
        const grandParentSubmenu = parentSubmenu.parent().parent(".sub-menu")
        if (grandParentSubmenu.length) {
          menuMainSubMenuToShow = grandParentSubmenu
          menuMainTopMenuItemToShow = grandParentSubmenu.parent() // top level menu item
        }
        break
      }
    }

    allMenuItems.removeClass("current-menu-item") // remove current menu from all menu items
    menuItem.addClass("current-menu-item") // new current menu item

    // show / hide download and faq menu accordingly
    if (isDownloadMenuItem) {
      // console.log("is download menu item")
      if (isAdditionalMenuItem) {
        // download menu in the additional menu was selected -> select the download menu item in the download menu
        const id = getObjectIDFromMenuItem(menuItem)
        downloadMenuItems.filter(`.wpse-object-id-${id}`).addClass("current-menu-item")
      } else {
        // item in download menu -> add current menu to download menu item in the additional menu
        const id = getObjectIDFromMenuItem(menuMainTopMenuItemToShow) // get page id from top level download menu item
        additionalMenuItems.filter(`.wpse-object-id-${id}`).addClass("current-menu-item")
      }
      // remove sub open from all other top level nav menu items. this class is used for the rotating of the triangle marker
      menuNavItemsLevel1.removeClass("sub-menu-visible")
      menuMainSubMenus.stop().slideUp(menuAnimationTime, "linear", () => navAndTitleHeight()) // hide all submenus
      faqMenu.stop().slideUp(menuAnimationTime, "linear", () => navAndTitleHeight())
      downloadMenu.stop().slideDown(menuAnimationTime, "linear", () => navAndTitleHeight())
    } else if (isFaqMenuItem) {
      // console.log("is faq menu item")

      if (isAdditionalMenuItem) {
        // download menu in the additional menu was selected -> select the download menu item in the download menu
        const id = getObjectIDFromMenuItem(menuItem)
        faqMenuItems.filter(`.wpse-object-id-${id}`).addClass("current-menu-item")
      } else {
        // item in download menu -> add current menu to download menu item in the additional menu
        const id = getObjectIDFromMenuItem(menuMainTopMenuItemToShow) // get page id from top level download menu item
        additionalMenuItems.filter(`.wpse-object-id-${id}`).addClass("current-menu-item")
      }
      menuNavItemsLevel1.removeClass("sub-menu-visible")
      menuMainSubMenus.stop().slideUp(menuAnimationTime, "linear", () => navAndTitleHeight()) // hide all submenus
      downloadMenu.stop().slideUp(menuAnimationTime, "linear", () => navAndTitleHeight())
      faqMenu.stop().slideDown(menuAnimationTime, "linear", () => navAndTitleHeight())
    } else {
      // nav menu or home (or additional menu)
      // console.log("is nav menu item or home")

      if (isHome) {
        menuNavItemsLevel1.removeClass("sub-menu-visible") // remove class from top level siblings
      } else {
        menuNavItemsLevel1.not(menuMainTopMenuItemToShow).removeClass("sub-menu-visible") // remove class from top level siblings
        menuMainTopMenuItemToShow.addClass("sub-menu-visible")
      }

      if (menuMainSubMenuToShow) {
        menuMainSubMenus
          .not(menuMainSubMenuToShow)
          .stop()
          .slideUp(menuAnimationTime, "linear", () => navAndTitleHeight()) // hide all submenus
        if (menuMainSubMenuToShow.is(":hidden")) menuMainSubMenuToShow.stop().slideDown(menuAnimationTime, "linear", () => navAndTitleHeight())
      } else {
        // no submenu to show -> slide up all submenus
        menuMainSubMenus.stop().slideUp(menuAnimationTime, "linear", () => navAndTitleHeight()) // hide all submenus
      }

      faqMenu.stop().slideUp(menuAnimationTime, "linear", () => navAndTitleHeight())
      downloadMenu.stop().slideUp(menuAnimationTime, "linear", () => navAndTitleHeight())
    }

    return menuLevel
  }

  function openPageWithID(pageID) {
    // pageID is typeof string, not number!!!
    console.log("open page with id:", pageID, typeof pageID)

    if (pageID) {
      requestedPageID = pageID
      clearTimeout(requestedPageIDTimeout)
      requestedPageIDTimeout = setTimeout(() => {
        requestedPageID = -1
      }, 500)

      if (!firstLoad) {
        closeNav()
        collapseHeader()
      } else {
        firstLoad = false
      }
      if (extMenuOpen) closeExtendedMenu()
      setCurrentContentID(pageID)
      showTitlePath(pageID)

      updateLanguageSwitcher()

      if (!pageLoader) {
        // page loader not yet created. first time after page load
        pageLoader = new PageLoader(pageID, menuData) // hand menu data into pageloader

        // callback function when page id changes ----------------------------------------------
        pageLoader.currentPageID = id => {
          console.log("current page id callback", id, $(`#${id}`).data("title"))
          setCurrentContentID(id)
          showTitlePath(id)

          const currMenuItem = $(`.wpse-object-id-${id}`) // find menu item referencing this page id

          if (currMenuItem.length && requestedPageID === -1) {
            // page referencing menu item found and we didn't request this same page by clicking on the menu
            setCurrentMenuItem(currMenuItem)
            const currMenuItemLink = currMenuItem.children("a")
            if (historyUpdate && updateHistory(currMenuItemLink.attr("href"), currMenuItemLink.text())) updateLanguageSwitcher()
          }
        }
      } else {
        historyUpdate = false // don't update history during page load
        // load or show the requested page
        pageLoader
          .loadPage(pageID, true) // load / show page with pageID and scroll to the page
          .then(() => {
            console.log("index.js page loaded", pageID)
            historyUpdate = true
          })
          .catch(() => console.log("page load error"))
      }
    }
  }

  function showTitlePath(id) {
    if (mobileView) {
      const postItemTitlePath = $(`#${id}`).find(".post-item-title__path")

      if (postItemTitlePath.length) {
        // reset all title paths except the current one
        $(".post-item-title__path").not(postItemTitlePath).stop().fadeOut()

        postItemTitlePath.stop().fadeIn().css("display", "flex")
      }
    }
  }

  function updateHistory(url, text) {
    console.log("history url", url)
    // history only gets updated when clicking on a different menu item, and not when navigating by url (goToWindowLocation)
    // and only if the url differs from the current one

    if (window.location.href !== url) {
      window.history.pushState(null, null, url)

      const slug = getSlugFromURL(url)

      // update the title tag when menu click. page load sets the title tag automatically (theme support 'title-tag')
      if (slug !== "") {
        // not home -> page | blogname
        document.title = (text ? text : slug) + " | " + blogName
        console.log("history: not home ", url, slug, blogName)
      } else {
        // is home -> blogname | blogdescription
        console.log("history: is home ", url, slug, blogName)
        document.title = blogName + " | " + blogDescription
      }
      console.log("updated history ", url, slug)

      return true
    } else {
      console.log("history not updated")
      return false
    }
  }

  function getSlugFromURL(url) {
    let slug = url.split(`${window.location.origin}/${languageSlug != "de" ? `${languageSlug}/` : ``}`)[1]
    // if (slug.charAt(0) === "/") slug = slug.slice(1)
    return slug
  }

  function updateLanguageSwitcher() {
    // console.log("---- update language switcher", currentContentID)

    if (currentContentID == "blog-page") {
      languageSwitcher.children("a").each(function () {
        // blog page is home page -> reset the language link to the languages home pages
        const ref = `${window.location.origin}/${$(this).data("slug") == "de" ? "" : $(this).data("slug")}`
        $(this).attr("href", ref)
        $(this).removeClass("language-disabled")
      })
    } else {
      // for other posts get the links of the translated posts
      jQuery.getJSON(localized.root_url + "/wp-json/translatedpostids/v1/get?id=" + currentContentID, transData => {
        // console.log("translation data:", transData)

        // loop all language switch items -> de, fr, it, en
        languageSwitcher.children("a").each(function () {
          const found = transData.find(obj => {
            return obj.lang === $(this).data("slug")
          })
          if (found) {
            // translated post found -> use permalink of trans post
            $(this).attr("href", found.href)
            $(this).removeClass("language-disabled")
          } else {
            // else use home page url
            const ref = `${window.location.origin}/${$(this).data("slug") == "de" ? "" : $(this).data("slug")}`
            $(this).attr("href", ref)
            // else disable the language
            $(this).addClass("language-disabled")
          }
        })
      })
    }
  }

  // page title click -> scroll to page top (call to reload the page)
  $(document).on("click", ".post-item-title", function (e) {
    const pageID = $(this).parent(".post-item-container").attr("id")
    openPageWithID(pageID)
  })

  $(document).on("click", ".post-item-title__path > span", function (e) {
    e.stopPropagation()
    const pageID = $(this).data("id").toString()
    console.log("open page from title path. ", pageID, typeof pageID)
    openPageWithID(pageID)
  })

  // open blog post detail on more button click
  $(document).on("click", ".blog-post-item", function (e) {
    e.preventDefault()
    // console.log("show blog post content", e.target.href, e.target.dataset.id)
    if (mobileView) closeNav()

    const blogPost = $(this)
    const content = blogPost.children(".blog-post-content")

    if (!content.is(":visible") || blogPost.hasClass("blog-post-item--little-content")) {
      showBlogPost(blogPost)
    } else {
      // content is visible -> close it and show excerpt.
      autoScrolling = true // prevent nav open from scroll by layout changes
      const excerpt = blogPost.children(".blog-post-excerpt")

      toggleElement(excerpt)

      if (content.is(":visible")) {
        toggleElement(content)
      }
      // reset url to home url
      updateHistory(`${localized.home_url}/`)
      setAutoScrollingResetTimeout(400)
    }
  })

  // restore normal link behaviour in blog posts. (not open close blog post)
  $(document).on("click", ".blog-post-item a", function (e) {
    // console.log("link in blog post click")
    e.stopPropagation()
  })

  $(document).on("click", ".blog-post__show-all-posts", function (e) {
    // console.log("link in blog post click")
    e.stopPropagation()
    openBlogPage()
  })

  function showBlogPost(blogPost) {
    // open blog post detail content if hidden or show little content blog post
    const excerpt = blogPost.children(".blog-post-excerpt")
    const content = blogPost.children(".blog-post-content")
    autoScrolling = true // prevent nav open from scroll by layout changes
    // first hide all contents of all blog posts, except this blog post
    $(".blog-post-item:not('.blog-post-item--little-content')")
      .children(".blog-post-content")
      .not(content)
      .each(function () {
        if ($(this).is(":visible")) {
          toggleElement($(this))
        }
      })

    // show excerpts of all posts, except this post. little content post have no excerpt
    $(".blog-post-excerpt")
      .not(excerpt)
      .each(function () {
        if ($(this).is(":hidden")) {
          toggleElement($(this))
        }
      })

    // update history
    const ref = blogPost.data("href")
    updateHistory(ref, blogPost.data("title"))

    // show blog post content and hide the excerpt
    if (excerpt.length) toggleElement(excerpt)

    if (content.is(":hidden")) {
      toggleElement(content)
    }
    // scroll to blog post

    clearTimeout(scrollToElementTimeout)
    scrollToElementTimeout = setTimeout(() => scrollToElement(blogPost), 250)
    setAutoScrollingResetTimeout(700)
  }

  function setAutoScrollingResetTimeout(ms) {
    ms = ms ?? 400
    clearTimeout(autoScrollingTimeout)
    autoScrollingTimeout = setTimeout(() => {
      autoScrolling = false
    }, ms)
  }

  // faq custom block frage onclick handler
  $(document).on("click", ".faq-block__frage", function () {
    console.log($(this).siblings(".faq-block__antwort"))
    const $antwort = $(this).siblings(".faq-block__antwort")
    $(".faq-block__antwort")
      .not($antwort)
      .each(function () {
        // if ($(this).is(":visible")) $(this).slideUp(800, "linear")
        if ($(this).is(":visible")) toggleElement($(this))
      })

    // $antwort.slideToggle(800, "linear")
    toggleElement($antwort)
  })

  function toggleElement($elem) {
    $elem.animate(
      {
        height: "toggle",
        opacity: "toggle"
      },
      200,
      "linear"
    )
  }

  function scrollToElement($elem) {
    let top = $elem.position().top

    if (mobileView) top += navHeight

    console.log("scroll to $elem", $elem.attr("id"), "top:", top, $elem.position().top)

    $("html, body").stop(true, true).animate(
      {
        scrollTop: top
      },
      400
    )
  }

  kontaktMenuEmail.on("click", function (e) {
    e.preventDefault()
    let mail = $(this).text()
    mail = mail.replace("@ ", "")
    mail = mail.replace("[at]", "@")
    window.open(`mailto:${mail}`)
    console.log(mail)
  })

  // get email address of person with id via ajax and open mail app
  $(document).on("click", ".person-block__email", function (e) {
    e.preventDefault()
    const id = e.target.dataset.id
    jQuery.getJSON(localized.root_url + "/wp-json/getmail/v1/get?id=" + id, email => {
      console.log("mailto: : : ", email)
      window.open(`mailto:${email}`)
    })
  })

  // get email address of person with id via ajax and open mail app
  $(document).on("click", ".email-block__email", function (e) {
    e.preventDefault()
    const postID = $(this).parents(".blog-post-item").attr("id") // get post id from blog post item container
    const blockID = $(this).parent().attr("id")
    // console.log("mailto: : : ", postID, blockID)

    jQuery.getJSON(`${localized.root_url}/wp-json/getmailaddress/v1/get?id=${postID}&blockid=${blockID}`, email => {
      console.log("mailto: : : ", email)
      window.open(`mailto:${email}`)
    })
  })

  // // prevent default for internal # links
  // $(document).on("click", '.posts-container a[href^="#"]', function (e) {
  //   const ref = $(this).attr("href")
  //   // if (ref.startsWith("#")) {
  //   e.preventDefault()

  //   if ($(this).parent().hasClass("nach-oben-link")) {
  //     console.log("nach oben")
  //     jQuery("html, body").animate({
  //       scrollTop: 0
  //     })
  //   } else {
  //     const $target = $(`${ref}`)

  //     if ($target.length) {
  //       const targetMarginTop = $target.css("margin-top").replace("px", "")
  //       // console.log(targetMarginTop)
  //       $("html, body").animate(
  //         {
  //           scrollTop: $target.offset().top - adminBarHeight - siteHeaderHeight - targetMarginTop - 52
  //         },
  //         400
  //       )
  //     }
  //   }
  //   // }
  // })

  function getMenuItemBySlug(slug) {
    // console.log("get menu item by slug:", slug)
    let found = false
    let index = 0
    menuItems.each(function (ind) {
      if ($(this).children("a").attr("href").includes(slug)) {
        found = true
        index = ind
        // console.log("menu link found", $(this).children("a").attr("href"), index)
        return false
      }
    })
    if (found) {
      return menuItems.eq(index)
    } else return null
  }

  // ----------------------------------------------------------------------------------------------------------------------------------------------------------------------

  function openBlogPage(postID) {
    postID = postID ?? null // if post id -> scroll to post, else restore scroll position tbd
    console.log("open the blog page. postID:", postID)

    if (!firstLoad) {
      closeNav()
      collapseHeader()
    } else {
      firstLoad = false
    }
    if (extMenuOpen) closeExtendedMenu()

    contentContainer.fadeIn()
    // hide all other page and post items
    let blogPage = $("#blog-page")
    $(".post-item-container").not(blogPage).hide()

    setCurrentContentID("blog-page")
    setCurrentMenuItem(menuItemHome)
    updateLanguageSwitcher()

    if (blogPage.length == 0) {
      // no blog page yet
      console.log("no blog page yet")
      // $.getJSON(localized.root_url + "/wp-json/wp/v2/posts/", posts => {
      // console.log("posts", posts)

      postsContainer.append(`
        <div id="blog-page" class="post-item-container initialized" data-title="Journal" data-history="journal">
                  
        </div>
                      `)

      blogPage = $("#blog-page")
      blogPage.hide()

      // add load more posts functionality -> create LoadMorePosts object with news page id
      loadMorePosts = new LoadMorePosts("blog-page", languageSlug)
      loadMorePosts.loadMorePosts()

      blogPage.fadeIn()
    } else {
      console.log("blog page exists")

      if (!blogPage.hasClass("initialized")) {
        loadMorePosts = new LoadMorePosts("blog-page", languageSlug)
        blogPage.addClass("initialized")
        blogPage.fadeIn()
      } else {
        blogPage.fadeIn()
      }

      // show requested post if id was supplied
      if (postID) {
        const post = blogPage.children(`#${postID}`)
        if (post.length) {
          // tbd
          console.log("blog post found, show it!", postID)
          showBlogPost(post)
        }
      } else {
        // scroll to top of blog page
        $("html, body").stop(true, true).animate(
          {
            scrollTop: 0
          },
          400
        )
      }
    }
  }

  // handle pathname ------------------------------------------------------------------------------------------------
  goToWindowLocation() // after page load to set correct view and menu

  function goToWindowLocation() {
    const pathname = window.location.pathname

    console.log("goto to location:", pathname) // pathname == "/...../" or "/"" (home)  "/fr/downloads/"

    if (pathname === "/" || pathname === `/${languageSlug}/`) {
      // is home page -> blog
      console.log("is home")
      openBlogPage()
      // --------------------------------------------------------
    } else {
      // not home
      // first check if it's a menu item. only pages in the menu

      const menuLinks = allMenuLinks.filter(`[href$="${window.location.href}"]`)
      if (menuLinks.length) {
        openPageLink(menuLinks)
      } else {
        // was not a menu link
        console.log("not menu link")
        // console.log("url is something else")
        // something different -> get post type for path
        $.getJSON(`${localized.root_url}/wp-json/pathtopost/v1/get?path=${pathname}`, post => {
          console.log(pathname, post)
          if (post.ID > 0) {
            // post found
            if (post.post_type == "page") {
              // page that's not in the menu
              openPageWithID(post.ID)
            } else if (post.post_type == "post") {
              // check if div with post id exists. (when post was loaded by single.php)
              const postItem = $(`#${post.ID}`)
              const blogPage = $(`#blog-page`)
              if (postItem.length && !blogPage.length) {
                // php loaded single post item exists, but no blog page container
                // hide all other post items
                $(".post-item-container").not(`#${post.ID}`).hide()
                $("li.menu-item").removeClass("current-menu-item")
                $(`#${post.ID}`).fadeIn()
              } else openBlogPage(post.ID)
            } else {
              // hide all other post items
              $(".post-item-container").not(`#${post.ID}`).hide()
              $("li.menu-item").removeClass("current-menu-item")
              $(`#${post.ID}`).fadeIn()
            }
          } else {
            console.log("error not a post")
            // is not a post -> show all post item container
            // hide all other post items
            $(".post-item-container").fadeIn()
            $("li.menu-item").removeClass("current-menu-item")
          }
        })
      }
    }
  }

  //
  function setCurrentContentID(id) {
    prevContentID = currentContentID
    currentContentID = id
    // console.log("current content:", currentContentID, prevContentID)
  }

  let resizeTimeout = null
  let resizeTimeout2 = null

  window.addEventListener("resize", () => {
    checkMobileView()

    clearTimeout(resizeTimeout)
    resizeTimeout = setTimeout(() => {
      touchSupport()
      onOrientationChange()
      setAdminBarHeight()
      calcVhPropertyFromClientHeight()
      navAndTitleHeight()
      checkMobileView()
      navAndTitleHeight()
      setCurrentMenuItem(allMenuItems.filter(".current-menu-item")) // find current menu and set it
      if (extMenuOpen) addLanguageSwitcherToExtMenu() // reorder the lang menu in the ext menu
    }, 500)

    clearTimeout(resizeTimeout2)
    resizeTimeout2 = setTimeout(() => {
      // for ios safari to get correct window height
      calcVhPropertyFromClientHeight()
    }, 1000)
  })

  function touchSupport() {
    touchsupport = "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0

    if (!touchsupport) {
      // browser doesn't support touch
      // document.documentElement.className += " non-touch"
      $("html").addClass("non-touch")
      $("html").removeClass("touch")
    } else {
      $("html").removeClass("non-touch")
      $("html").addClass("touch")
    }
  }

  function checkMobileView() {
    if ($(window).width() < mobileBreakpoint) {
      mobileView = true
      $("html").addClass("mobile-view")
      $("html").removeClass("desktop-view")
    } else {
      // desktop view
      mobileView = false
      $("html").removeClass("mobile-view")
      $("html").addClass("desktop-view")
      // reset mobile header settings
      siteTitle.css("height", "")
      siteTitle.css("padding", "")
      siteTitleLink.css("padding", "")
    }
    // console.log("window width", $(window).width());
  }

  function onOrientationChange() {
    // detect orientation change
    if ((orientationLandscape && window.innerWidth < window.innerHeight) || (!orientationLandscape && window.innerWidth >= window.innerHeight)) {
      setLandscape()
    }
  }

  function setLandscape() {
    // store new orientation
    orientationLandscape = window.innerWidth >= window.innerHeight

    if (orientationLandscape) {
      $("html").addClass("orientation-landscape")
      $("html").removeClass("orientation-portrait")
    } else {
      $("html").removeClass("orientation-landscape")
      $("html").addClass("orientation-portrait")
    }
    // console.log("orientation changed, landscape:", orientationLandscape);
  }

  function setAdminBarHeight() {
    let wpabh = 0
    const adminBar = $("#wpadminbar")
    if (adminBar.length) {
      wpabh = adminBar.outerHeight()
    }
    adminBarHeight = wpabh
    $(":root").css("--adminBarHeight", `${adminBarHeight}px`)
  }

  function navAndTitleHeight() {
    siteTitleHeight = siteTitle.outerHeight()
    $(":root").css("--siteTitleHeight", `${siteTitleHeight}px`)
    $(":root").css("--siteTitleWidth", `${siteTitle.width()}px`)
    siteHeaderHeight = siteHeader.outerHeight()
    $(":root").css("--siteHeaderHeight", `${siteHeaderHeight}px`)

    navHeight = siteMenu.outerHeight()
    $(":root").css("--navHeight", `${navHeight}px`)
  }

  function calcVhPropertyFromClientHeight() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    // let vh = document.documentElement.clientHeight * 0.01
    const vh = window.innerHeight * 0.01
    // console.log("height: ", vh * 100);
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`)
    // if (debug) console.log("client height: ", document.documentElement.clientHeight);
  }

  // reload page on broswer back / forward
  window.onpopstate = function (event) {
    console.log("onpopstate", event, window.location)
    goToWindowLocation()
  }

  document.addEventListener("scroll", function (e) {
    // const currentScroll = window.pageYOffset
    const currentScroll = window.scrollY
    const scrollDelta = 0.5 * (prevScrollDelta + (currentScroll - lastScroll))
    // console.log("scroll: ", currentScroll, lastScroll, scrollDelta, prevScrollDelta)

    prevScrollDelta = scrollDelta

    if (mobileView) {
      // if (window.scrollY === 0 && !autoScrolling && !pageLoader?.autoScrolling && !extMenuOpen) {
      if (window.scrollY === 0) {
        // on top
        // console.log("on top")
        closeNav() // unstick nav menu
        // check if current page is the top page tbd. intersection observer misses when scrolling too fast
      } else if (currentScroll > lastScroll && scrollDelta > 5 && !autoScrolling && !pageLoader?.pageLoadingExtended) {
        // DOWN ---
        // reduce sensitivity
        console.log("down - collapse header")
        closeNav()
        collapseHeader()
      } else if (currentScroll < lastScroll && scrollDelta < -10 && window.scrollY > siteTitleHeight && !autoScrolling && !pageLoader?.autoScrolling) {
        // UP --- UP
        // console.log("up")
        // openNav()
      } else if (currentScroll > 1 && !autoScrolling && !pageLoader?.pageLoadingExtended && !pageLoader?.autoScrolling) {
        console.log("|| scroll > 1 - collapse header")
        //closeNav()
        // safety header collapse
        collapseHeader()
      }
    }

    lastScroll = Math.max(0, currentScroll)
  })

  function getObjectIDFromMenuItem(elem) {
    // get page id from menu item classlist (wpse-object-id-XX)
    let classlist = elem.attr("class").split(/\s+/)
    let pageID
    $.each(classlist, function (index, item) {
      if (item.startsWith("wpse-object-id-")) {
        const splits = item.split("wpse-object-id-")
        if (splits.length > 1) pageID = splits[1]
        return false
      }
    })
    return pageID
  }

  function checkFlexGap() {
    // create flex container with row-gap set
    var flex = document.createElement("div")
    flex.style.display = "flex"
    flex.style.flexDirection = "column"
    flex.style.rowGap = "1px"
    flex.style.position = "absolute"

    // create two, elements inside it
    flex.appendChild(document.createElement("div"))
    flex.appendChild(document.createElement("div"))

    // append to the DOM (needed to obtain scrollHeight)
    document.body.appendChild(flex)
    var isSupported = flex.scrollHeight === 1 // flex container should be 1px high from the row-gap
    flex.parentNode.removeChild(flex)

    return isSupported
  }

  function observeElement($this) {
    if (observer) {
      observer.disconnect()
    } else {
      observer = new window.IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            // console.log("ENTER")
          } else {
            // console.log("LEAVE")
          }
        },
        {
          root: null,
          threshold: 0.01 // set offset 0 means trigger if atleast 0% of element in viewport
        }
      )
    }
    observer.observe($this[0])
  }

  function buildMenuData() {
    const menuData = []
    $(".menu-main > .menu-item")
      .add(".menu-download > .menu-item")
      .add(".menu-faq > .menu-item")
      .each(function (index) {
        // console.log("top level menu item", $(this))
        const menuItem = {
          pageID: getObjectIDFromMenuItem($(this)),
          slug: $(this).children("a").text(),
          subMenus: []
        }

        // get 1st level submenus
        const subMenus = $(this).children(".sub-menu")?.children(".menu-item")
        subMenus.each(function () {
          // console.log("sub level menu item", $(this).attr("id"))
          const subMenuItem = {
            pageID: getObjectIDFromMenuItem($(this)),
            slug: $(this).children("a").text(),
            subMenus: []
          }

          // get all 2nd level submenus
          const subSubMenus = $(this).children(".sub-menu")?.children(".menu-item")
          subSubMenus.each(function () {
            const subSubMenuItem = {
              pageID: getObjectIDFromMenuItem($(this)),
              slug: $(this).children("a").text()
            }
            subMenuItem.subMenus.push(subSubMenuItem)
          })

          menuItem.subMenus.push(subMenuItem)
        })
        menuData.push(menuItem)
      })
    // console.log("menu data", menuData)
    return menuData
  }

  function restructureMainMenu() {
    // take the first 3 top level menus and the remaining menus and put them in 2 separate container divs
    // for the extended menu. this allow the menu to be split in two columns

    const topMenuItems = menuMain.children(".menu-item")

    if (topMenuItems.length >= 2) {
      const $leftContainer = $('<div class="container-left"></div>').appendTo(menuMain)
      const $rightContainer = $('<div class="container-right"></div>').appendTo(menuMain)
      for (let i = 0; i < topMenuItems.length; i++) {
        if (i <= Math.floor(topMenuItems.length / 2)) {
          topMenuItems.eq(i).appendTo($leftContainer)
        } else {
          topMenuItems.eq(i).appendTo($rightContainer)
        }
      }
    }
  }

  function getMenuIndexesOfCurrentPage(currentID, menuData) {
    let indexes = {
      index: null,
      subIndex: null,
      subSubIndex: null
    }

    for (let i = 0; i < menuData.length; i++) {
      // console.log(menuData[i])
      if (currentID === menuData[i].pageID) {
        // id found in menu
        indexes.index = i
        break
      }

      for (let j = 0; j < menuData[i].subMenus.length; j++) {
        // console.log(menuData[i].subMenus[j])
        if (currentID === menuData[i].subMenus[j].pageID) {
          // id found in sub menu
          indexes.index = i
          indexes.subIndex = j
          break
        }

        for (let k = 0; k < menuData[i].subMenus[j].subMenus.length; k++) {
          // console.log(menuData[i].subMenus[j].subMenus[k])
          if (currentID === menuData[i].subMenus[j].subMenus[k].pageID) {
            // id found in sub sub menu
            indexes.index = i
            indexes.subIndex = j
            indexes.subSubIndex = k
            break
          }
        }
      }
    }
    // console.log("menu indexes found", indexes)
    return indexes
  }
})
