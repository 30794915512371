// import Lazyload from "lazyload"

class LoadMorePosts {
  constructor(postsPageID, languageSlug) {
    this.root_url = localized?.root_url ?? ""
    // console.log("root url from module laod more posts", this.root_url)
    this.lastPostObserver
    this.postEnterObserver
    this.postsLoading
    this.postsPageID = postsPageID ?? 0
    this.languageSlug = languageSlug ?? "de"
    this.newsPageContent = jQuery(`#${this.postsPageID}`)
    this.observeLastPost()
    this.minWordCount = 55 // posts with 55 or less words will be shown in full, not the excerpt and without read more button
  }

  events() {}

  loadMorePosts() {
    if (!this.postsLoading && this.newsPageContent.length) {
      this.postsLoading = true
      const offset = jQuery(".post-item-content__post").length // number of loaded post items
      console.log(`${localized.root_url}/wp-json/wp/v2/posts?lang=${this.languageSlug}&per_page=${localized.posts_per_page}&offset=${offset}&orderby=date&order=desc`)
      jQuery.getJSON(`${localized.root_url}/wp-json/wp/v2/posts?lang=${this.languageSlug}&per_page=${localized.posts_per_page}&offset=${offset}&orderby=date&order=desc`, posts => {
        console.log(posts, offset)
        if (posts.length) {
          const newPosts = jQuery(`
          ${posts
            .map(post => {
              // count the words in the content. if less than 56 always show the content
              let contentString = post.content.rendered.replace(/(<([^>]+)>)/gi, "") // remove html tags
              contentString = contentString.replace(/(?:\r\n|\r|\n)/g, " ")
              // contentString = post.content.rendered
              let words = contentString
                .trim()
                .split(" ")
                .filter(str => str != "")
              const wordcount = words.length
              return `
          <article id="${post.id}" class="post-item-content__post blog-post-item ${wordcount <= this.minWordCount ? `blog-post-item--little-content` : ``}" data-id="${post.id}" data-title="${post.title.rendered}" data-href="${post.link}" data-history="${post.slug}"  data-word-count="${wordcount}">
          ${
            post.featured_image
              ? `
            <div class="blog-post-image">
              <img class="img-container" 
              src="${post.featured_image.src}" 
              srcset="${post.featured_image.srcset}" 
              data-width="${post.featured_image.width}"
              data-height="${post.featured_image.height}"
              sizes="(max-width: 781px) 100vw, 66vw"
              alt="${post.featured_image.alt}">
            </div>
          `
              : ``
          }

          <div class="blog-post-title-date">
              <span>${post.title.rendered}</span><span> / ${post.date_formated}</span>
          </div>

          ${
            wordcount <= this.minWordCount
              ? ``
              : `<div class="blog-post-excerpt">  
                ${post.excerpt.rendered}
              </div>`
          }
          

          <div class="blog-post-content">${post.content.rendered}</div>

          ${wordcount <= this.minWordCount ? `` : `<a class="blog-post__read-more" href="${post.link}" data-id="${post.id}"><img></a>`}
           
        </article>
          `
            })
            .join("")}
        `).appendTo(this.newsPageContent)

          newPosts.filter("article.blog-post-item").each((index, post) => {
            this.observePostEnter(post)
            console.log("observe post", post)
          })

          this.observeLastPost()
          this.postsLoading = false
        } else {
          // no more posts
          this.lastPostObserver.disconnect()

          // this.newsPageContent.append(`
          // <div class="post-item-content__post article">
          //   <div class="nach-oben-button">nach oben</div>
          // </div>`)
          // this.newsPageContent.find(".nach-oben-button").on("click", function () {
          //   jQuery("html, body").animate({
          //     scrollTop: 0
          //   })
          // })
        }
      })
    }
  }

  observeLastPost() {
    if (this.lastPostObserver) {
      this.lastPostObserver.disconnect()
    } else {
      this.lastPostObserver = new window.IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            // console.log("Last Post ENTER")
            this.loadMorePosts()
          }
        },
        {
          root: null,
          threshold: 0.01 // set offset 0 means trigger if atleast 0% of element in viewport
        }
      )
    }
    const lastPost = document.querySelector(".post-item-content__post:last-child")

    lastPost ? this.lastPostObserver.observe(lastPost) : ""
  }

  observePostEnter(post) {
    if (!this.postEnterObserver) {
      this.postEnterObserver = new window.IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            console.log("Post ENTER", entry.target)
            entry.target.classList.add("blog-post-item--in-view")
            this.postEnterObserver.unobserve(entry.target)
          }
        },
        {
          root: null,
          threshold: 0 // set offset 0 means trigger if atleast 0% of element in viewport
        }
      )
    }
    console.log("observer post. top is", post.getBoundingClientRect().top, window.innerHeight)

    // add the in view class, if the loaded post is already in the viewport (intersection observer not triggering correctly)
    if (post.getBoundingClientRect().top <= window.innerHeight + 200) {
      post.classList.add("blog-post-item--in-view")
    } else {
      this.postEnterObserver.observe(post)
    }
  }

  addLazyloadToElement(elem) {
    // https://github.com/tuupola/lazyload
    // $("img.lazyload").lazyload();
    // lazyload();

    // const elem = document.querySelector(`[id="${elemID}"]`);
    // const elem = $elem[0]
    const images = elem.querySelectorAll("img.lazyload")

    // console.log("lazyload images", images, " in container ", $elem);

    new Lazyload(images, {
      root: null,
      rootMargin: "50px",
      threshold: 0
    })

    // add load event listener for lazyload images
    // $elem.find(`img.lazyload`).on("load", function () {
    //   // console.log("img on elem xx loaded", $elem.attr('id'));
    //   jQuery(this).addClass("loaded") // add loaded class to image -> fade in with css opacity 1
    // })

    images.forEach(image => {
      image.addEventListener("load", () => {
        image.classList.add("loaded")
      })
    })
  }
}

export default LoadMorePosts
